
import React, {Fragment, useEffect, useState} from 'react';
import SpinnerCounter from "../components/spinner-counter";
import Header from "../components/header";
import TextScrollAnimation from "../components/text-scroll-animation";
import CarouselHorizontalScroll from "../components/carousel-horizontal-scroll";
import {accordionHome, carouselHome} from "../utils/static-values";
import Accordion from "../components/accordion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
import {Animated} from "react-animated-css";
import Marquee from "../components/marquee";
import {urls} from "../routes/routes-data";
import {Link} from "react-router-dom";
import {getVideos} from "../modules/actions";
import SEO from "../components/SEO";
import images from "../utils/images";
import Whatsapp from "../components/whatsapp";

const Home = ({initialSpinner, initialShowHome}) => {

    const dataCarousel = carouselHome
    const dataAccordion = accordionHome
    const [sizeTextScroll, setSizeTextScroll] = useState(135)
    const [width, setWidth] = useState(window.innerWidth);
    const [video, setVideo] = useState(null)


    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    const changeSizeTextScroll =()=> {
        if (width <= 1424) {
            setSizeTextScroll(80)
            if (width <= 600) {
                setSizeTextScroll(60)
                if (width <= 380) {
                    setSizeTextScroll(45)
                }
            }
        } else {
            setSizeTextScroll(135)
        }
    }

    const getVideo = async ()=> {
        try {
            const response = await getVideos()
            const filterVideo= response?.items?.filter(item => item?.fields?.title === "video-home")
            setVideo(filterVideo[0])
        }catch (error) {
            console.error(error)
        }
    }




    useEffect(() => {

        window.addEventListener("resize", handleResize);
        getVideo()
    }, []);

    useEffect(() => {
        changeSizeTextScroll()
    }, [width]);



    return (
        <Fragment>
            <SEO
                title="HelloBrand - Home"
                metaDescription="Hacemos que tus clientes opriman muchas veces el botón comprar"
                image={images?.CarouselHome1}
            />


            {initialSpinner && <SpinnerCounter/>}

            {
                initialShowHome &&

                <Animated
                    animationIn="fadeIn"
                    animationInDuration={1200}
                >
                    <Header/>
                    <div className="home">
                        <div className="container-top">
                            <h1 className="title">
                                <TextScrollAnimation
                                    text1="En HelloBrand"
                                    text2="Our Team"
                                    height={sizeTextScroll}
                                    linkText2={urls.team}
                                />
                                Hacemos que
                                <br/>
                                <TextScrollAnimation
                                    text1="tus clientes"
                                    text2="Casos de éxito"
                                    linkText2={urls.successStories}
                                    height={sizeTextScroll}
                                />
                                opriman el <br/> botón comprar.</h1>

                            {
                                video &&
                                <div className="video-frame">
                                    <video
                                        className="video-frame"
                                        autoPlay={true}
                                        loop={true}
                                        muted={true}
                                        playsInline={true}
                                    >

                                        <source src={`https:${video?.fields?.video?.fields?.file?.url}`}  type="video/mp4"
                                        />
                                    </video>
                                </div>
                            }


                        </div>

                        {
                            dataCarousel && dataCarousel.length > 0 &&
                            <CarouselHorizontalScroll
                                title="Así funciona HelloBrand"
                                data={dataCarousel}
                            />
                        }

                        {
                            dataAccordion && dataAccordion.length > 0 &&
                            <Accordion data={dataAccordion}/>
                        }

                        <div className="banner-home">
                            <div className="banner-home__grid-banner">
                                <h2>Atraemos a los clientes</h2>
                                <h3>Y hacemos que hagan lo que tu quieres que hagan.</h3>
                            </div>
                        </div>

                        <div className="container-button">
                            <Link to={urls.workTogether} className="button-xl">
                                Quiero una cotización
                                <FontAwesomeIcon icon={faArrowRight} />
                            </Link>
                        </div>

                        <Marquee
                            title="Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works"
                            info=">Inspírate con nuestro trabajo"
                            link={urls.successStories}
                        />

                    </div>
                </Animated>
            }


        </Fragment>
    );
};


export default Home;


