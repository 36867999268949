
import React, {useEffect, useState} from 'react';

import Header from "../components/header";

import {customersLogos, opinionsCustomer} from "../utils/static-values";
import SimpleSlider from "../components/simple-slider";
import {Link} from "react-router-dom";
import {urls} from "../routes/routes-data";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import Marquee from "../components/marquee";
import SEO from "../components/SEO";



const Opinions = () => {


    const customers = customersLogos;
    const opinions = opinionsCustomer;

    useEffect(()=> {
        window.scrollTo(0,0)
    }, [])


    return (
        <>
            <SEO
                title="HelloBrand - Opiniones"
                metaDescription="Hellobrand meta description hellobrand, mejora tu marca"
            />

            <Header/>

            <div className="opinions">
                <div className="opinions__header">
                    <h1>Opiniones</h1>
                    <h2>Deja que nuestros clientes te lo cuenten.</h2>
                </div>

                <div className="opinions__grid-top">
                    <div className="opinions__grid-top__video-box">

                    </div>
                    <div className="opinions__grid-top__grid-counter">
                        <div className="opinions__grid-top__grid-counter__counter">
                            <h3>5 +</h3>
                            <p>Años de experiencia</p>
                        </div>
                        <div className="opinions__grid-top__grid-counter__counter">
                            <h3>7</h3>
                            <p>Paises</p>
                        </div>
                        <div className="opinions__grid-top__grid-counter__counter">
                            <h3>$3M</h3>
                            <p>De USD en ventas a nuestros clientes</p>
                        </div>
                        <div className="opinions__grid-top__grid-counter__counter">
                            <h3>5 +</h3>
                            <p>Años de experiencia</p>
                        </div>

                    </div>

                </div>


                <div className="opinions__grid-customers">
                    {
                        customers && customers.map((item, id)=> {
                            return (
                                <div className="opinions__grid-customers__item" key={id}>
                                    <img src={item?.img} alt={item?.description} />
                                </div>
                            )
                        })
                    }
                </div>

                <SimpleSlider data={opinions}/>

                <div className="opinions__content-button">
                    <Link to={urls.workTogether} className="button-xl">
                        Quiero una cotización
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>


            </div>

            <Marquee
                title="Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works"
                info=">Inspírate con nuestro trabajo"
                link={urls.successStories}
            />

        </>
    );
};


export default Opinions;


