
import React, {useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {Animated} from "react-animated-css";

const CarouselDouble = ({data}) => {


    const [dataSlider, setDataSlider] = useState(data)
    const [stateAction, setStateAction] = useState({
        activeSlide: 0,
        activeSlideCurrent: 0
    })
    const [mapNumbers, setMapNumbers] = useState([]);


    const settings = {
        dots: false,
        infinite: true,
        fade: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        swipeToSlide: true,
        beforeChange: (current, next) => setStateAction({ activeSlide: next }),
        afterChange: current => setStateAction({ activeSlideCurrent: current }),
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />

    };



    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;

        return (
            <div className="top-prev">
                <div
                    className={`${className} slick-prev-custom`}
                    style={{ ...style, display: "block"}}
                    onClick={onClick}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    01
                </div>

            </div>
        );
    }

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div className="top-next">
                <div
                    className={`${className} slick-next-custom `}
                    style={{ ...style, display: "block"}}
                    onClick={onClick}
                >
                    {renderSliderCount(props?.slideCount)}
                    <FontAwesomeIcon icon={faArrowRight} />
                </div>

            </div>
        );
    }

    const renderSliderCount =(count)=> {

        const value = count.toString()
        const str = value.length
        if (str > 1 ) {
            return count
        } else {
            return `0${count}`
        }

    }

    // onlyse use two slider at the same time
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);

    }, []);





    const [isMouseOverSlider, setIsMouseOverSlider] = useState(false);
    const [lastScrollDirection, setLastScrollDirection] = useState(null);
    const [timer, setTimer] = useState(null);

    const handleMouseEnter = () => {
        setIsMouseOverSlider(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOverSlider(false);
    };

    const handleWheel = (event) => {
        if (isMouseOverSlider) {
            const currentScrollDirection = event.deltaY > 0 ? 'down' : 'up';

            if (currentScrollDirection !== lastScrollDirection) {
                if (currentScrollDirection === "up") {
                    handlePrevClick();
                } else {
                    handleNextClick();
                }
                setLastScrollDirection(currentScrollDirection);
            }

            clearTimeout(timer);
            setTimer(setTimeout(() => setLastScrollDirection(null), 200));
        }
    };

    const handlePrevClick = () => {
        const prevButton = document.querySelector('.slick-prev-custom');
        if (prevButton) {
            prevButton.click();
        }
    };

    const handleNextClick = () => {
        const nextButton = document.querySelector('.slick-next-custom');

        if (nextButton) {
            nextButton.click();
        }
    };

    const generateArray = (num) => {
        if (num <= 0) {
            setMapNumbers([]);
        } else {
            const newArray = Array.from({ length: num }, (_, index) => index + 1);
            setMapNumbers(newArray);
        }
    };

    useEffect(() => {
        generateArray(data.length)
    }, []);


    return (
        <>
            <div className="carousel-double">

                <div className="carousel-double__list-item-left">

                    <div>
                        {
                            mapNumbers.map((item, id)=> {
                                return (
                                    <div className="carousel-double__list-item-left__item" key={id}>
                                        <p className={item === stateAction.activeSlideCurrent+1 ? 'bold' : ''}>{item}</p>
                                        {
                                            item === stateAction.activeSlideCurrent+1 ?
                                                <div className="line-black"/>
                                                :
                                                <></>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <Slider {...settings}
                        asNavFor={nav1}
                        ref={slider2}
                >
                    {
                        dataSlider && dataSlider.map((item, id)=> {
                            let activeAction = false
                            if (item.id === stateAction.activeSlideCurrent) {
                                activeAction = true
                            }
                            return (
                                <div
                                    key={id}
                                >
                                    <div className="carousel-double__item" >
                                        <Animated
                                            animationIn="fadeIn"
                                            animationOut="fadeOutUp"
                                            animationInDuration={500}
                                            animationOutDuration={1000}
                                            isVisible={activeAction}
                                        >
                                            <h3 style={{ whiteSpace: 'pre-line' }}>{item?.title}</h3>
                                            <div className="carousel-double__item__grid-info">
                                                <p>{item?.description}</p>
                                                {/*<div className="circle-button">
                                                    <a href="#stories" className="button">
                                                        Ver todos los casos de éxito >>
                                                    </a>
                                                </div>*/}
                                            </div>
                                        </Animated>

                                        <Animated
                                            animationIn="fadeIn"
                                            animationOut="fadeOutRight"
                                            animationInDuration={500}
                                            animationOutDuration={1000}
                                            isVisible={activeAction}
                                            className="carousel-double__item__image">
                                            <img src={item?.image}/>
                                        </Animated>
                                    </div>
                                </div>
                            )
                        })
                    }

                </Slider>
            </div>
        </>
    );
};


export default CarouselDouble;


